var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{style:({
      padding: '0px 0px 9px',
      'text-align': 'center',
      'text-size-adjust': '100%',
      'word-break': 'break-word',
      color: 'rgb(209, 23, 98)',
      'font-family': 'Verdana, Geneva, sans-serif',
      'font-size': '12px',
      'line-height': '150%',
    }),attrs:{"valign":"top"}},[_c('table',{attrs:{"align":"center"}},[_c('tbody',[_c('tr',[_c('td',{style:({ width: '50%' })},[_c('div',[_c('a',{style:({
                  'text-decoration': 'none',
                  'text-size-adjust': '100%',
                  color: 'rgb(209, 23, 98) !important',
                  cursor: 'default',
                  'font-weight': 'normal',
                }),attrs:{"href":"tel:1-512-298-5285"}},[_vm._v(" US: +1 512-298-5285")]),_c('span',[_vm._v("|")]),_c('a',{style:({
                  'text-decoration': 'none',
                  'text-size-adjust': '100%',
                  color: 'rgb(209, 23, 98) !important',
                  cursor: 'default',
                  'font-weight': 'normal',
                }),attrs:{"href":"tel:44-203-239-0003"}},[_vm._v("UK: +44 203 239-0003")])])]),_c('td',{style:({ width: '50%' })},[_c('div',{style:({ 'text-align': 'right' })},[_c('a',{style:({
                  'text-decoration': 'none',
                  'text-size-adjust': '100%',
                  color: 'rgb(209, 23, 98) !important',
                  'font-weight': 'normal',
                }),attrs:{"href":"mailto:experience@encounterlatinamerica.com"}},[_vm._v(" experience@encounterlatinamerica.com")])])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }