var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.dayServicesWithFullData.length > 0)?_c('tr',[_c('td',{style:({ 'padding-top': '9px', 'text-size-adjust': '100%' }),attrs:{"valign":"top"}},[_c('table',{staticClass:"mcnTextContentContainer",style:({
        'max-width': '100%',
        'min-width': '100%',
        'border-collapse': 'collapse',
        'text-size-adjust': '100%',
      }),attrs:{"align":"left","border":"0","cellpadding":"0","cellspacing":"0","width":"100%"}},[_c('tbody',[_c('tr',[_c('td',{style:({
              padding: '0px 18px 9px',
              'text-size-adjust': '100%',
              'word-break': 'break-word',
              'font-family': 'Verdana, Geneva, sans-serif',
              'font-size': '14px',
              'line-height': '200%',
              'text-align': 'left',
              color: 'rgb(77, 77, 77)',
            }),attrs:{"valign":"top"}},[_c('h5',[_vm._v("Services in day "+_vm._s(_vm.day.start_day)+":")]),_c('ul',_vm._l((_vm.dayServicesWithFullData),function(service,index){return _c('li',{key:index},[(
                    !_vm.isDayInsideOfServiceRange(_vm.day.start_date, service) &&
                    _vm.helper.isAccommodationPackageService(
                      service.id_service_type
                    )
                  )?_c('strong',[_vm._v(" "+_vm._s(service.check_in && _vm.day.start_date === service.start_date ? "Check in " + _vm.helper.validateCheckInAndCheckOut(service.check_in) : "")+" "+_vm._s(service.check_out && _vm.day.start_date === service.end_date ? "Check out " + _vm.helper.validateCheckInAndCheckOut(service.check_out) : "")+" ")]):_vm._e(),_vm._v(" "+_vm._s(service && "name" in service ? service.name.value : "")+" ")])}),0)])])])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }