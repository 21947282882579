var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{style:({ 'padding-top': '9px', 'text-size-adjust': '100%' }),attrs:{"valign":"top"}},[_c('table',{staticClass:"mcnTextContentContainer",style:({
        'max-width': '100%',
        'min-width': '100%',
        'border-collapse': 'collapse',
        'text-size-adjust': '100%',
      }),attrs:{"align":"left","border":"0","cellpadding":"0","cellspacing":"0","width":"100%"}},[_c('tbody',[_c('tr',[_c('td',{style:({
              padding: '0px 18px 9px',
              color: 'rgb(0, 0, 0)',
              'font-family':
                'Open Sans, Helvetica Neue, Helvetica, Arial,sans-serif',
              'font-size': '34px',
              'line-height': '100%',
              'text-size-adjust': '100%',
              'word-break': 'break-word',
              'text-align': 'left',
            }),attrs:{"valign":"top"}},[_c('div',{style:({ 'text-align': 'center' })},[_c('span',{style:({
                  'font-size': '12px',
                  'letter-spacing': '5px',
                  'text-transform': 'uppercase',
                })},[_vm._v(" 📅 "+_vm._s(_vm.format(_vm.day.start_date)))]),_c('br'),_c('strong',[_c('span',{style:({ 'font-size': '24px', color: 'rgb(77, 77, 77)' })},[_c('span',[_vm._v("Day "+_vm._s(_vm.day.start_day)+":")]),_vm._v(" "+_vm._s(_vm.day && "title" in _vm.day ? _vm.day.title.value : ""))])]),_c('br'),_c('span',{style:({
                  'font-size': '12px',
                  'letter-spacing': '5px',
                  'text-transform': 'uppercase',
                })},[_vm._v(" 📌 "+_vm._s(_vm.day.location .map(function (x) { return x.location_name; }) .join(", ")))])])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }